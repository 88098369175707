export const SUPPORTED_NETWORKS_CONFIRM = {
	1: 12,
	56: 12,
	137: 12,
	1284: 12,
	1285: 12,
	42: 12,
	97: 12,
	80001: 12,
	1287: 12,
	1338: 12,
	8453: 12,
	84532: 12,
	11155111: 12,
};
