let pynths = {
  1284: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Binance Coin",
      symbol: "pBNB",
      key: "BNB",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 4,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 5,
      favorite: false,
      name: "Polkadot",
      symbol: "pDOT",
      key: "DOT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 17,
      favorite: false,
      symbol: "pATOM",
      key: "ATOM",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 18,
      favorite: false,
      symbol: "pGLMR",
      key: "GLMR",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 19,
      favorite: false,
      symbol: "pCAKE",
      key: "CAKE",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  1285: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Binance Coin",
      symbol: "pBNB",
      key: "BNB",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 8,
      favorite: false,
      name: "Aave",
      symbol: "pAAVE",
      key: "AAVE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 16,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 17,
      favorite: false,
      name: "Polkadot",
      symbol: "pDOT",
      key: "DOT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 31,
      favorite: false,
      name: "Fhantom",
      symbol: "pFTM",
      key: "FTM",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  1287: [
    {
      id: 0,
      favorite: false,
      name: "Pynths pUSD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Binance Coin",
      symbol: "pBNB",
      key: "BNB",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 4,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 5,
      favorite: false,
      name: "Polkadot",
      symbol: "pDOT",
      key: "DOT",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  8453: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 8,
      favorite: false,
      name: "Compound",
      symbol: "pCOMP",
      key: "COMP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 12,
      favorite: false,
      name: "Yearn Finance",
      symbol: "pYFI",
      key: "YFI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 10,
      favorite: false,
      name: "Synthetix",
      symbol: "pSNX",
      key: "SNX",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 14,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 21,
      favorite: false,
      name: "Solana",
      symbol: "pSOL",
      key: "SOL",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 22,
      favorite: false,
      symbol: "pAPT",
      key: "APT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 23,
      favorite: false,
      symbol: "pOP",
      key: "OP",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  84532: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 14,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  56: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Binance Coin",
      symbol: "pBNB",
      key: "BNB",
      categories: ["crypto"],
    },
    {
      id: 5,
      favorite: false,
      name: "Uniswap",
      symbol: "pUNI",
      key: "UNI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 14,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 15,
      favorite: false,
      name: "Polkadot",
      symbol: "pDOT",
      key: "DOT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 24,
      favorite: false,
      name: "PancakeSwap",
      symbol: "pCAKE",
      key: "CAKE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 25,
      favorite: false,
      name: "Cardano",
      symbol: "pADA",
      key: "ADA",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 26,
      favorite: false,
      name: "PAX Gold",
      symbol: "pPAXG",
      key: "PAXG",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 7,
      favorite: false,
      name: "Aave",
      symbol: "pAAVE",
      key: "AAVE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 17,
      favorite: false,
      symbol: "pATOM",
      key: "ATOM",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 4,
      favorite: false,
      name: "Avalanche",
      symbol: "pAVAX",
      key: "AVAX",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 17,
      favorite: false,
      name: "Axie Infinity",
      symbol: "pAXS",
      key: "AXS",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 27,
      favorite: false,
      name: "Bitcoin Cash",
      symbol: "pBCH",
      key: "BCH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 8,
      favorite: false,
      name: "Compound",
      symbol: "pCOMP",
      key: "COMP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 29,
      favorite: false,
      name: "Dogecoin",
      symbol: "pDOGE",
      key: "DOGE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 30,
      favorite: false,
      name: "Eos",
      symbol: "pEOS",
      key: "EOS",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 45,
      favorite: false,
      name: "Fetch.ai",
      symbol: "pFET",
      key: "FET",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 31,
      favorite: false,
      name: "Fhantom",
      symbol: "pFTM",
      key: "FTM",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 34,
      favorite: false,
      name: "Internet Computer",
      symbol: "pICP",
      key: "ICP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 28,
      favorite: false,
      name: "Litecoin",
      symbol: "pLTC",
      key: "LTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 23,
      favorite: false,
      name: "Polygon",
      symbol: "pMATIC",
      key: "MATIC",
      categories: [],
      decimal: 18,
    },
    {
      id: 26,
      favorite: false,
      name: "PAX Gold",
      symbol: "pPAXG",
      key: "PAXG",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 46,
      favorite: false,
      name: "Near Protocol",
      symbol: "pNEAR",
      key: "NEAR",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 47,
      favorite: false,
      name: "Shiba Inu",
      symbol: "pSHIB",
      key: "SHIB",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 21,
      favorite: false,
      name: "Solana",
      symbol: "pSOL",
      key: "SOL",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 9,
      favorite: false,
      name: "Sushi",
      symbol: "pSUSHI",
      key: "SUSHI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 16,
      favorite: false,
      name: "Ripple",
      symbol: "pXRP",
      key: "XRP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 12,
      favorite: false,
      name: "Yearn Finance",
      symbol: "pYFI",
      key: "YFI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 13,
      favorite: false,
      name: "1 Inch",
      symbol: "p1INCH",
      key: "1INCH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 36,
      favorite: false,
      name: "Gold",
      symbol: "pXAU",
      key: "XAU",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 37,
      favorite: false,
      name: "Silver",
      symbol: "pXAG",
      key: "XAG",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 18,
      favorite: false,
      name: "Euro",
      symbol: "pEUR",
      key: "EUR",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 38,
      favorite: false,
      name: "Pound Sterling",
      symbol: "pGBP",
      key: "GBP",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 39,
      favorite: false,
      name: "Japanese Yen",
      symbol: "pJPY",
      key: "JPY",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 40,
      favorite: false,
      name: "Swiss Franc",
      symbol: "pCHF",
      key: "CHF",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 41,
      favorite: false,
      name: "Australian Dollar",
      symbol: "pAUD",
      key: "AUD",
      categories: ["forex"],
      decimal: 18,
    },
  ],
  97: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: [],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: [],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: [],
      decimal: 18,
    },
    {
      id: 14,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: [],
      decimal: 18,
    },
    {
      id: 23,
      favorite: false,
      name: "Polygon",
      symbol: "pMATIC",
      key: "MATIC",
      categories: [],
      decimal: 18,
    },
    {
      id: 24,
      favorite: false,
      name: "PancakeSwap",
      symbol: "pCAKE",
      key: "CAKE",
      categories: [],
      decimal: 18,
    },
  ],
  137: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Binance Coin",
      symbol: "pBNB",
      key: "BNB",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 4,
      favorite: false,
      name: "Avalanche",
      symbol: "pAVAX",
      key: "AVAX",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 5,
      favorite: false,
      name: "Uniswap",
      symbol: "pUNI",
      key: "UNI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 6,
      favorite: false,
      name: "Maker",
      symbol: "pMKR",
      key: "MKR",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 7,
      favorite: false,
      name: "Aave",
      symbol: "pAAVE",
      key: "AAVE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 8,
      favorite: false,
      name: "Compound",
      symbol: "pCOMP",
      key: "COMP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 9,
      favorite: false,
      name: "Sushi",
      symbol: "pSUSHI",
      key: "SUSHI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 10,
      favorite: false,
      name: "Synthetix",
      symbol: "pSNX",
      key: "SNX",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 11,
      favorite: false,
      name: "Curve",
      symbol: "pCRV",
      key: "CRV",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 12,
      favorite: false,
      name: "Yearn Finance",
      symbol: "pYFI",
      key: "YFI",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 13,
      favorite: false,
      name: "1 Inch",
      symbol: "p1INCH",
      key: "1INCH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 14,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 15,
      favorite: false,
      name: "Polkadot",
      symbol: "pDOT",
      key: "DOT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 16,
      favorite: false,
      name: "Ripple",
      symbol: "pXRP",
      key: "XRP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 17,
      favorite: false,
      name: "Axie Infinity",
      symbol: "pAXS",
      key: "AXS",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 18,
      favorite: false,
      name: "Euro",
      symbol: "pEUR",
      key: "EUR",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 19,
      favorite: false,
      name: "Sandbox",
      symbol: "pSAND",
      key: "SAND",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 20,
      favorite: false,
      name: "Decentraland",
      symbol: "pMANA",
      key: "MANA",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 21,
      favorite: false,
      name: "Solana",
      symbol: "pSOL",
      key: "SOL",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 25,
      favorite: false,
      name: "Cardano",
      symbol: "pADA",
      key: "ADA",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 26,
      favorite: false,
      name: "PAX Gold",
      symbol: "pPAXG",
      key: "PAXG",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 27,
      favorite: false,
      name: "Bitcoin Cash",
      symbol: "pBCH",
      key: "BCH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 28,
      favorite: false,
      name: "Litecoin",
      symbol: "pLTC",
      key: "LTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 29,
      favorite: false,
      name: "Dogecoin",
      symbol: "pDOGE",
      key: "DOGE",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 30,
      favorite: false,
      name: "Eos",
      symbol: "pEOS",
      key: "EOS",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 31,
      favorite: false,
      name: "Fhantom",
      symbol: "pFTM",
      key: "FTM",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 32,
      favorite: false,
      name: "The Graph",
      symbol: "pGRT",
      key: "GRT",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 33,
      favorite: false,
      name: "Polygon",
      symbol: "pMATIC",
      key: "MATIC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 34,
      favorite: false,
      name: "Internet Computer",
      symbol: "pICP",
      key: "ICP",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 35,
      favorite: false,
      name: "Paxos Gold",
      symbol: "pPAXG",
      key: "PAXG",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 36,
      favorite: false,
      name: "Gold",
      symbol: "pXAU",
      key: "XAU",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 37,
      favorite: false,
      name: "Silver",
      symbol: "pXAG",
      key: "XAG",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 38,
      favorite: false,
      name: "Pound Sterling",
      symbol: "pGBP",
      key: "GBP",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 39,
      favorite: false,
      name: "Japanese Yen",
      symbol: "pJPY",
      key: "JPY",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 40,
      favorite: false,
      name: "Swiss Franc",
      symbol: "pCHF",
      key: "CHF",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 41,
      favorite: false,
      name: "Australian Dollar",
      symbol: "pAUD",
      key: "AUD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 42,
      favorite: false,
      name: "Canadian Dollar",
      symbol: "pCAD",
      key: "CAD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 43,
      favorite: false,
      name: "New Zealand Dollar",
      symbol: "pNZD",
      key: "NZD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 44,
      favorite: false,
      name: "DeFi Pulse Index",
      symbol: "pDPI",
      key: "DPI",
      categories: ["indexes"],
      decimal: 18,
    },
  ],
  80001: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 2,
      favorite: false,
      name: "Ethereum",
      symbol: "pETH",
      key: "ETH",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 3,
      favorite: false,
      name: "Euro",
      symbol: "pEUR",
      key: "EUR",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 4,
      favorite: false,
      name: "Chainlink",
      symbol: "pLINK",
      key: "LINK",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 5,
      favorite: false,
      name: "Polygon",
      symbol: "pMATIC",
      key: "MATIC",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 6,
      favorite: false,
      name: "SandBox",
      symbol: "pSAND",
      key: "SAND",
      categories: ["crypto"],
      decimal: 18,
    },
    {
      id: 7,
      favorite: false,
      name: "Solana",
      symbol: "pSOL",
      key: "SOL",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
  11155111: [
    {
      id: 0,
      favorite: false,
      name: "USD",
      symbol: "pUSD",
      key: "USD",
      categories: ["forex"],
      decimal: 18,
    },
    {
      id: 1,
      favorite: false,
      name: "Bitcoin",
      symbol: "pBTC",
      key: "BTC",
      categories: ["crypto"],
      decimal: 18,
    },
  ],
};

export default pynths;
