export const en = {
	stake: {
		intro1: 'WHAT WOULD',
		intro2: 'YOU LIKE TO DO?',
		explanation: {
			mint: 'Mint pUSD By staking PERI',
			burn: 'Burn pUSD to unlock your staked PERI',
			reward: 'Claim rewards from PERI and Pynths'
		}
	}
}
